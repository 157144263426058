import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import logoMuni from "./logoMuni.svg";



const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      marginTop: '2vw'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    }
  },
  text:{
    textAlign: 'center',
    marginBottom: '2vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      fontWeight: 300,
      lineHeight: 1.23,
      letterSpacing: '0em',
      width: '75%'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.334,
      letterSpacing: '0em'
    }

  },
  instalar:{
    [theme.breakpoints.down('xs')]: {
      paddingTop: '7.5%',
      paddingBottom: '5%'
    },
    paddingTop: 20,
    paddingBottom: 20

  },
  close:{
    position: 'absolute',
    right:'2.5%',
    top: '2.5%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function InstallDialog(props) {

  const classes = useStyles();
  return (
    <div>
      <Dialog fullWidth={true} maxWidth={'sm'} open={props.open} aria-labelledby="form-dialog-title">
      <div className={classes.close}>
        <IconButton onClick={props.cancel} color="inherit">
          <ClearIcon />
        </IconButton>
      </div>
        <DialogContent>

          <div className={classes.container}>
          <img className={classes.logo} src={logoMuni} alt="La imagen del logo de Rivadavia DH"/>
          <Typography className={classes.text} variant='h5' gutterBottom >
            ¿Desear agregar esta aplicación al Inicio?
          </Typography>
          <Button fullWidth title="añadir-orden" className={classes.instalar} onClick={props.installApp} >
            Instalar
          </Button>
          </div>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
