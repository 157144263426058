import React from "react";
import {Container, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import fixingBugs from "./fixingBugs.svg";

const useStyles = makeStyles((theme) => ({
  error:{
    textAlign: 'center'
  },
  title:{
    marginTop:'8vh',
    color: '#4DB257',
    fontWeight: '500',
    textAlign: 'center'
  },
  fixBugs:{
    height: '31.4vh'
  },
  general:{
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function ErrorFallback({error, resetErrorBoundary}) {
  const classes=useStyles();
  return (
    <Container className={classes.container} maxWidth="sm">
      <div role="alert" className={classes.general}>
        <img className={classes.fixBugs} src={fixingBugs} alt="Imagen de chica arreglando bugs" />
        <div className={classes.textos}>
          <Typography className={classes.title} variant='h5'>Algo salió mal:</Typography>
          <p className={classes.error} >{error?.message || 'Este error no tiene mensaje'}</p>
        </div>
      </div>
    </Container>
  )
}
