export default function handleLoginFail(setError,e){
  console.warn(e);
  //Agregar Errores
  //Error de logeo
  if(e.response?.data?.error === 'Invalid LogIn'){
    setError("username", {
          type: "manual",
          message: "Usuario o contraseña incorrecta"
        });
    setError("password", {
              type: "manual",
              message: "Usuario o contraseña incorrecta"
            });
    }
  //Usuario suspendido
  if(e.response?.data?.error === 'Usuario Suspendido'){
    setError("username", {
          type: "manual",
          message: "Este usuario ha sido suspendido"
        });
    setError("password", {
              type: "manual",
              message: "Este usuario ha sido suspendido"
            });
  }
}
