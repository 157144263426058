import React, {useState, useEffect,Fragment, useRef} from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {Link as LinkRouter} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoginForm from "./LoginForm";

import svgFamilia from "./loginfamilia.svg";
import svgComercio from "./logincomercio.svg";
import api from "../utils/api";

import {useSpring, animated} from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useMeasure } from "react-use";

const useStyles = makeStyles((theme) => ({
  slider:{
    margin: '4.21vh 0',
    width: '13.33%'
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 100,
    color: 'white',
  },
  square:{
    backgroundColor: '#B8B8B8',
    height: '1.875vh',
    width: '1.875vh',
    cursor: 'pointer'
  },
  dot:{
    width: '1.875vh',
    backgroundColor: '#4DB257',
    borderRadius: '50%',
    height: '1.875vh',
    cursor: 'pointer'
  },
  root: {
    position: 'relative',
    minHeight: '100vh',
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'hidden'
  },top:{
    minHeight: '52.96vh',
  },
  image: {
    position: 'relative',
    marginTop: '5.78vh',
    width: '300%',
    justifyContent: 'space-between',
    display: 'flex'
  },
  containerSvg:{
    width: '33.33%',
    display: 'flex',
    justifyContent: 'center'
  },
  svg:{
    willChange: 'transform',
    position: 'relative',
    cursor: 'grab',
    height: '37.03vh',
    "&:active":{
      cursor: 'grabbing'
    }
  },
  bodyBack:{
    position: 'absolute',
    width: '100%',
    height: '100%',
    left:0,
    right:0,
    zIndex: -1,
    backgroundColor: '#4DB257',
    opacity: 0.5,
  },
  loginWrapper:{
  overflow: 'hidden',
  position: 'absolute',
  width: '100%',
  bottom:0,
  //paddingBottom: '2vh',
  borderTopLeftRadius: '68px',
  borderTopRightRadius: '68px',
  willChange: 'height',
  //transition: 'height .35s ease-in',
  backgroundColor: '#B8E0BC'},
  login: {
    marginTop: '6.87vh'
    //height: '100%'
    //height: '37.24vh'
  },
  container: {
    margin: '0 12.77%'
  }
}));

export default function Login(props) {
  console.log("LOGIN RENDERED");
  //IF ITS ALREADY LOGIN REDIRECT
  useEffect(()=>{
        document.title='Rivadavia | Desarrollo Humano';
        let active=true;
        (async function getItem(){
          try{
            const token= await api.get('/login/me');
            if(token && active){
              setLoading(false);
              console.log(token.data);
              props.setLoggedIn(true);
              props.setAuth(token.data.data);
              props.history.push(`/${token.data.data.role}`);
            }
          }catch(e){
            console.log(e);
            active && setLoading(false);
          }
        })();
        return ()=>{active=false};
  },[]);

  //Familylogin or Sellerlogin
  const [value, setValue] = useState(0);
  const [isLoading,setLoading]=useState(true);
  //STYLES
  const classes = useStyles();

  //ANIMATION
  const [loginHover,toggleHover] = useState(false);
  const [ref, { height }] = useMeasure();

  function getMargin(){
    const height=window.innerHeight;
    const margin=height*0.0687;
    return margin;
  }
  function heightPrimario(){
    const heightLarge=window.innerHeight;
    const margin=getMargin();
    const porcentajeBuscado=heightLarge*0.4703;
    return porcentajeBuscado;
  };


  const [contentHeight, setContentHeight] = useState(heightPrimario());
  useEffect(() => {
  //Sets initial height
  setContentHeight(heightPrimario());

  //Adds resize event listener
  window.addEventListener("resize", setContentHeight(height+getMargin()));

  // Clean-up
  return window.removeEventListener("resize", setContentHeight(height+getMargin()));
}, [height]);

  let widthW=(window.innerWidth < 600) ? window.innerWidth : 600;
  const [propsX, set] = useSpring(() => ({
     x: widthW,
   }));
   const x=propsX.x;
  const bind = useDrag(({ down, movement: [mx], direction: [xDir],velocity}) => {
     const middleground=widthW/2;
     const trigger = velocity > 0.37 ;
     const dir = xDir < 0 ? -1 : 1;
     if (!down) {
        if(trigger && (dir>0)){

          set({ x: widthW });
          value && setValue(0);
          return;
        } else if(trigger && (dir<0)){

          set({ x: -widthW });
          !value && setValue(1);
          return;
        }

        if (mx > 0) {
          set({ x: widthW });
          value && setValue(0);
        }else if(mx<0){
          set({ x: -widthW });
          !value && setValue(1);
        }
        return;
      }
      return set({ x: mx });
    },{
      initial: () => [x.getValue(), 0],
      threshold: 15,
      //bounds: { left: (widthW + 0.15*widthW), right: -(widthW + 0.15*widthW) },
      rubberband: true,
    });
  const spring = useSpring({height: loginHover ? `${window.innerHeight*0.62}px` : `${(contentHeight)}px`});

  let rendered;
  if(value === 0){
    rendered = <LoginForm title="una Familia" setAuth={props.setAuth} isLoggedIn={props.isLoggedIn} setLoggedIn={props.setLoggedIn}  history={props.history} value={value} setLoading={setLoading}/>;
  }else{

    rendered = <LoginForm title="un Comercio"  setAuth={props.setAuth} isLoggedIn={props.isLoggedIn} setLoggedIn={props.setLoggedIn} history={props.history} value={value} setLoading={setLoading}/>;
  }

  return (
     <Fragment>
     <Backdrop className={classes.backdrop} open={isLoading} onClick={()=>setLoading(false)}>
       <CircularProgress color="inherit" />
     </Backdrop>
       <Container className={classes.root} maxWidth="sm" style={{visibility: isLoading ? 'hidden' : 'inherit'}}>
         <Box onClick={()=>toggleHover(false)} className={classes.top} display='flex' alignItems='center' flexDirection='column'>
           <animated.div draggable='false' {...bind()}  style={{ transform: x.interpolate(x => `translate(${x}px)`) }} className={classes.image} >
             <div className={classes.containerSvg}>
               <img draggable='false' className={classes.svg} src={svgFamilia} alt="Imagen de una familia" />
             </div>
             <div className={classes.containerSvg}>
               <img draggable='false' className={classes.svg} src={svgComercio} alt="Imagen de una comercio" />
             </div>
           </animated.div>
           <Box className={classes.slider} display='flex' justifyContent='space-between' flexDirection='row'>
             <animated.div style={{
               borderRadius: x.interpolate({range: [widthW,-widthW], output: ['50%','0%']}),
               backgroundColor: x.interpolate({range: [widthW,-widthW], output: ['#4DB257','#B8B8B8']})
             }} onClick={()=>{
               set({ x: widthW });
               value && setValue(0);
             }} className={classes.dot} title="dot" />
             <animated.div style={{
               borderRadius: x.interpolate({range: [widthW,-widthW], output: ['0%','50%']}),
               backgroundColor: x.interpolate({range: [widthW,-widthW], output: ['#B8B8B8','#F9A826']})
             }} onClick={()=>{
               set({ x: -widthW });
               !value && setValue(1);
             }} className={classes.square} title="square" />
           </Box>
         </Box>
         <animated.div title="loginW" onClick={()=>toggleHover(true)} className={classes.loginWrapper} style={{height: spring.height,backgroundColor: x.interpolate({range: [widthW,-widthW], output: ['#B8E0BC','#F5CA84'] })}}>
         <div ref={ref} className={classes.login}>
           <div className={classes.container}>
             {rendered}
           </div>
         </div>
         </animated.div>
       </Container>
     </Fragment>
  );
}
