import React, {Fragment,useState} from 'react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { TextField, NoSsr } from '@material-ui/core';
import {useForm} from "react-hook-form";
import { animated} from 'react-spring';

//IMPORT axios
import api from "../utils/api";
import handleLoginFail from "../utils/handleLoginFail";

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiInput-underline:after {

      border-bottom: 2px solid #000000;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.87);
    }
    &.Mui-focused fieldset {
      border-color:  ${props => props.colores};
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.80)',
    '&:hover':{
      backgroundColor: 'rgba(0,0,0,0.80)',
      color: 'white',
    },
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function LoginForm(props) {

  const {register, errors, handleSubmit, setError} = useForm();
  const [showPass , setShowPass] = useState(false);

  let urlEnd=props.value ? 'seller': 'family';

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

   const handleFormSubmit = async (data)=>{
     console.log(data);
    if(!props.isLoggedIn){
      try{
        props.setLoading(true);
        const response= await api.post(`/login/${urlEnd}`,data);
        props.setLoading(false);
        if(response){
          handleLoginSuccess(response.data.data);
        }
      }catch(e){
        props.setLoading(false);
        handleLoginFail(setError,e);
      }
    }
  }
  function handleLoginSuccess(data){
    props.setLoggedIn(true);
    props.setAuth(data);
    props.history.push(`/${data.role}`);
  }
  const classes = useStyles(props);
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Acceder a {props.title}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit(handleFormSubmit)} >
          <NoSsr>
          <StyledTextField
            inputRef={register({
               required: 'Este campo es obligatorio.',
               minLength: {
                 value: 5,
                 message: 'El usuario debe poseer mas de 5 caracteres.'
              }
            })}
            colores={props.color}
            margin="normal"
            required
            fullWidth
            id="user"
            label="Usuario"
            name="username"
            inputProps={{ "data-testid": "username" }}
            autoComplete="user"
            autoFocus
            error={errors.username ? true : false}
            helperText={errors.username ? errors.username.message : ''}
          />
          <StyledTextField
            inputRef={register({
             required: 'Este campo es obligatorio.'
            })}
            type={showPass ? 'text' : 'password'}
            colores={props.color}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>setShowPass(!showPass)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
            inputProps={{ "data-testid": "password" }}
            id="password"
            autoComplete="current-password"
            error={errors.password ? true : false}
            helperText={errors.password ? errors.password.message : ''}
          />
          </NoSsr>
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
            //style={{backgroundColor: props.color.x.interpolate({range: [widthW,-widthW], output: ['#009688','#F9A826']}) }}
          >
            Iniciar Sesion
          </Button>
        </form>
        {(window.innerHeight > 650) ? <div><br/><br/></div> : null}
      </div>
    </Fragment>
  );
}
