import React from "react";
import mobile404 from "./mobile404.svg";
import desktop404 from "./desktop404.svg";
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center'
  },
  desktop: {
    width: 1000
  }
}));

export default function NotFound(props){
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Hidden mdUp>
        <img src={mobile404} alt="Imagen de 404 not found"/>
      </Hidden>
      <Hidden smDown>
        <img src={desktop404} className={classes.desktop} alt="Imagen de 404 not found"/>
       </Hidden>
    </div>
  );
}
