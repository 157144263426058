import axios from 'axios';
import urlOrigin from "./urlOrigin";

export const API_URL = urlOrigin();

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export default api;
