import React, { useState, lazy, Fragment, Suspense,useEffect} from "react";
import {BrowserRouter as Router,Switch,Route,Redirect} from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from "./utils/ErrorFallback";

//COMPONENTS
import Login from "./Login/Login";
import LoginAdmin from "./Login/LoginAdmin";
import Loading from "./utils/Loading";
import Error from "./utils/Error";
import NotFound from "./404/404.js";
import InstallDialog from "./utils/InstallDialog.js";

//login imports
import { ProtectedRoute } from "./utils/protected.route";
//Lazy imports
const Dashboard = lazy(()=> import("./Admin/Dashboard"));
const Seller = lazy(()=> import("./Seller/Seller"));
const User = lazy(()=> import("./User/User"));
const Test = lazy(()=> import("./Test/Test"));

const App = (props) => {

  //IS LOGGED Without using useContext
  const [isLoggedIn,setLoggedIn] = useState(false);
  const [auth,setAuth]= useState(null);
  const [hasError,setHasError]=useState(false);
  const [error,setError] = useState(null);
  const [installDialog,setInstallDialog] = useState(false);

  let countRef=React.useRef(0);
  let promptRef= React.useRef(null);

  //const [prompt,setPrompt] = useState(null);

  function isDateFromStorageHigher(){
    //debuger;
    const today=new Date();
    let date = JSON.parse(localStorage.getItem('installOptions'));
    if(!date) return false;
    date= new Date(date.date);
    if(date > today){
      return true;
    }
    return false;
  }

  function getInstallObject(){
    //debuger;
    let obj={};
    obj.days=7;
    obj.date=addDays(new Date(),7);
    let item=localStorage.getItem('installOptions');
    if(!item) return obj;
    item=JSON.parse(item);
    if(item){
      let {days}=item;
      obj.date=addDays(new Date() ,days*2);
      obj.days=days*2;
    }
    return obj;
  }

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(()=>{
    const handler = e => {
      // For older browsers
      countRef.current++;
      if(countRef.current<2 && !isDateFromStorageHigher()){
        //debugger;
        e.preventDefault();

        promptRef.current=e;
        // See if the app is already installed, in that case, do nothing
        if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
          return false;
        }

        //If its more than 600px width dont show message
        if(window.matchMedia && !window.matchMedia('(max-width: 600px)').matches){
          return false;
        }

        // Set the state variable to make button visible
        setInstallDialog(true);
        // Set date for dialog
        const obj = getInstallObject();
        localStorage.setItem('installOptions', JSON.stringify(obj));
      }
    }

    window.addEventListener('beforeinstallprompt' , handler);

    return () => {
      window.removeEventListener("beforeinstallprompt",handler);
    };

  },[]);

  const installApp=async ()=>{
    const prompt = promptRef.current;
    if(!prompt) return false;
    prompt.prompt();
    let outcome = await prompt.userChoice;
    if(outcome.outcome=='accepted'){
      console.log("App Installed")
    }
    else{
      console.log("App not installed");
    }
    // Remove the event reference
    //setPrompt(null);

    // Hide the button
    promptRef.current=null;
    setInstallDialog(false);
  }

  console.log("App rendered");
        return (
          <Fragment>
            <ErrorBoundary
            FallbackComponent={ErrorFallback}

            >
            <Router>
              <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/login/admin" render={props => (
                  <LoginAdmin {...props}
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn}
                  auth={auth}
                  setAuth={setAuth}
                  setHasError={setHasError}
                  setError={setError}
                  />)}
                  />
                <ProtectedRoute path="/admin"
                  component={Dashboard}
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn}
                  auth={auth}
                  setAuth={setAuth}
                  setHasError={setHasError}
                  setError={setError}
                  />
                <Route exact path="/" render={props=>(
                  <Login {...props}
                  setHasError={setHasError}
                  setError={setError}
                  auth={auth}
                  setAuth={setAuth}
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn} /> )}
                  />
                <Route exact path="/user" render={props => (
                  <User {...props}
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn}
                  auth={auth}
                  setAuth={setAuth}
                  setHasError={setHasError}
                  setError={setError}
                   />)}
                  />
                <Route path="/seller" render={props => (
                  <Seller {...props}
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn}
                  auth={auth}
                  setAuth={setAuth}
                  setHasError={setHasError}
                  setError={setError}
                  />)}
                  />
                <Route exact path="/test" component={Test} />
                <Route path="*" component={NotFound} />
              </Switch>
              </Suspense>
            </Router>
            {installDialog &&
              <InstallDialog
              cancel={()=> setInstallDialog(false)}
              open={installDialog}
              installApp={() => installApp()}
              />
            }
            <Error
              open={hasError}
              close={()=>setHasError(!hasError)}
              error={error}
            />
          </ErrorBoundary>
          </Fragment>
        );
    }

export default App;
