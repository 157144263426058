import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  component: Component, isLoggedIn,setLoggedIn,auth,setAuth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          return <Component  isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} auth={auth} setAuth={setAuth} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login/admin",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
