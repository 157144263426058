import React, { useState, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import { Link as LinkRouter, BrowserRouter } from "react-router-dom";

import api from "../utils/api";
import handleLoginFail from "../utils/handleLoginFail";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 100,
    color: 'white'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginAdmin(props) {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  console.log("login admin rendered");

  //Redirect on login
  useEffect(() => {
    document.title = 'RivadaviaDH | Administración';
    let active = true;
    (async function getWhoamI() {
      try {
        const who = await api.get('/login/me');
        if (who && active) {
          setLoading(false);
          props.setLoggedIn(true);
          props.setAuth(who.data.data);
          if (props.location.state?.from && who.data.data.role === 'admin') {
            props.history.push(props.location.state.from.pathname);
          } else {
            props.history.push(`/${who.data.data.role}`);
          }
        }

      } catch (e) {
        active && setLoading(false);
        console.log(e);
      }
    })();
    return () => { active = false };

  }, []);

  const { register, errors, handleSubmit, setError } = useForm();

  const handleFormSubmit = async (data) => {
    if (!props.isLoggedIn) {
      try {
        setLoading(true);
        const response = await api.post("/login/admin", data);
        setLoading(false);
        if (response) {
          handleLoginSuccess(response.data);
        }
      } catch (e) {
        setLoading(false);
        handleLoginFail(setError, e);
      }
    }
  }

  function handleLoginSuccess(data) {
    props.setAuth(data.data);
    props.setLoggedIn(true);
    props.history.push("/admin");
  }

  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={isLoading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/*<BrowserRouter>*/}
      <LinkRouter to="/">
        <IconButton color="primary">
          <ArrowBackIcon />
        </IconButton>
      </LinkRouter>
      {/*</BrowserRouter>*/}
      <Container component="main" maxWidth="xs" style={{ visibility: isLoading ? 'hidden' : 'inherit' }}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Acceder a  la Administracion
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit(handleFormSubmit)}>
            <TextField
              inputRef={register({
                required: 'Este campo es obligatorio.',
                minLength: {
                  value: 5,
                  message: 'El usuario debe poseer mas de 5 caracteres.'
                }
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputProps={{ "data-testid": "username" }}
              id="user"
              label="Usuario"
              name="username"
              autoComplete="user"
              autoFocus
              error={errors.username ? true : false}
              helperText={errors.username ? errors.username.message : ''}
            />
            <TextField
              inputRef={register({
                required: 'Este campo es obligatorio.'
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputProps={{ "data-testid": "password" }}
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password ? true : false}
              helperText={errors.password ? errors.password.message : ''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar Sesion
            </Button>
          </form>
        </div>
      </Container>
    </Fragment>
  );
}
