import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ErrorIcon from '@material-ui/icons/Error';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  icon:{
    paddingRight: '1.25vw',
    color: 'red'
  }

}));

export default function Error(props) {

  const classes= useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.title}>
             <ErrorIcon className={classes.icon}/>
             {props.error?.title}
          </div>
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.error?.msg}
            <br/>
            Para mas información contactarse a <a href="https://wa.me/5492392406416">2392406416</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {props.children}
    </div>
  );
}
